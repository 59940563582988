<template>
    <div class='statisticsTotal'>
        <div class='img'>
            <img src='../../assets/img/activityStatistics/img2.png' alt=''>
            <span></span>
        </div>
        <div class='totalBox'>
            <div class='totalNum' v-for='(item,index) in option_total' :key='index'>
                <div class='title'>{{item.name}}</div>
                <div class='num'>
                    {{item.value}}<span v-if='index==1'>%</span>
                </div>
                <div class='leftLine'>
                    <span></span>
                    <span></span>
                </div>
                <div class='topLine'></div>
                <div class='bottomLine'></div>
            </div>
        </div>

    </div>
</template>

<script setup>
import http from '../../utils/request';
import { onMounted, ref } from 'vue';
let option_total=ref([{name:'本月活跃用户数',value:0},{name:'本月用户活跃度',value:0},{name:'应用总数',value:0},{name:'注册用户总数',value:0}])
function getTotal() {
    http.get('/zty-api/system/activity/display', {})
        .then((res) => {
            option_total.value[0].value=res.data.monthActivityUserNum
            option_total.value[1].value=res.data.monthUserActivity
            option_total.value[2].value=res.data.applicationTotal
            option_total.value[3].value=res.data.userRegisterTotal
        })
        .catch((err) => {
            console.log(err);
        });
}

onMounted(() => {
    getTotal();
});
</script>

<style scoped lang='less'>
.statisticsTotal{
    width: 98%;
    height: 148px;
    background: #FFFFFF;
    box-shadow: 0 0 10px -6px rgba(0,0,0,0.30);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 15px 24px;
    box-sizing: border-box;
    .img{
        width: 144px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        img{
            width: 90px;
            //height: 100px;
        }
        span{
            width: 1px;
            height: 88px;
            background: #D8D8D8;
        }
    }
    .totalBox{
        display: flex;
        flex: 1;
        justify-content: space-around;

        .totalNum{
            width: 200px;
            height: 100px;
            background: #E7F3FF;
            position: relative;
            padding: 17px 24px;
            box-sizing: border-box;
            .topLine{
                width: 200px;
                position: absolute;
                top: 0px;
                left: 0;
                height: 1px;
                background-image: linear-gradient(to right, white, #1492FF 50%, #1492FF 50%, white );
            }
            .bottomLine{
                width: 200px;
                position: absolute;
                bottom: 0px;
                left: 0;
                height: 1px;
                background-image: linear-gradient(to right, white, #1492FF 50%, #1492FF 50%, white );
            }
            .title{
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
            }
            .num{
                font-family: DINAlternate-Bold;
                font-weight: 700;
                font-size: 28px;
                color: #1492FF;
                margin-top: 8px;
            }
            .leftLine{
                width: 12px;
                height: 76px;
                position: absolute;
                left: 0px;
                top: 12px;
                span:nth-child(1){
                    width: 12px;
                    height: 76px;
                    //opacity: 0.6;
                    background: rgba(20, 146, 255, 0.4);
                    border-radius: 2px;
                    filter: blur(3px);
                    position: absolute;
                    left: 0;
                    right: 0;
                }

                span:nth-child(2){
                    display: inline-block;
                    width: 4px;
                    height: 76px;
                    background: #1492FF;
                    border-radius: 2px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    z-index: 1;
                }
            }
        }
    }

}
</style>
