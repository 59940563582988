<template>
<div class='statisticsList'>
    <div class='title'>
        <div>
            <img src='../../assets/img/activityStatistics/img1.png' alt=''>
            <span class='text'>{{listType=='area'?'各地用户活跃情况':'各应用用户活跃情况'}}</span>
        </div>

        <el-tooltip :visible="visible">
            <template #content>
                <div style='width: 430px'>
                    <h3>说明:</h3>
                    <div v-for='(item,index) in explainList[listType]' :key="index+listType">{{item}}</div>
                </div>
            </template>
            <div style='display: inline-block'>
                <span>统计口经</span><img src='../../assets/img/activityStatistics/mark.png' alt=''>
            </div>
<!--            <el-button  type='primary' link>-->
<!--                -->
<!--            </el-button>-->

        </el-tooltip>
    </div>
    <div>
        <div class='search'>
            <el-form :inline="true" :model="query">
                <el-form-item label="">
                    <el-select v-model="query.externalId" class="search-input "  style='width: 150px' size='mini' clearable  placeholder="请选择地区">
                        <el-option
                            v-for="(item, index) in option_region"
                            :props='propsRegion'
                            :key="item.externalId"
                            :label="item.organizationName"
                            :value="item.externalId"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="" >
                    <el-select v-model="query.appUuidList" class="search-input" multiple  collapse-tags size='mini' style='width: 150px'  clearable placeholder="请选择应用"  >
                        <el-option
                            v-for="(item, index) in option_map"
                            :key="item.organizationName"
                            :label="item.organizationName"
                            :value="item.externalId"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="" >
                    <el-select v-model="query.type" class="search-input"  size='mini' style='width: 150px'  clearable placeholder="请选择统计维度"  >
                        <el-option
                            v-for="(item, index) in option_dimension"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="applyType">
                    <el-date-picker
                        v-model="searchData"
                        type="daterange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        size="default"
                        :shortcuts="shortcuts"
                        @change="handleDate"
                        value-format="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        style='width: 295px;'
                    />
                </el-form-item>
                <el-form-item label="" prop="applyType">
                    <div>
                        <el-button @click="search" type="primary">查 询</el-button>
                        <el-button @click="resize" plain>重 置</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div>
            <div class='tableTitle'>
                <div >排名</div>
                <div>{{listType=='area'?'行政区划':'应用名称 '}}</div>
                <div style='display: flex;align-items: center;justify-content: center'>
                    <div>活跃用户数</div>
                    <div style='padding-top: 5px;cursor: pointer'>
                        <div :class="{'activeTriangle':activeTriangleUser==1?true:false}" @click="sortArr(1,'activityUserNum')"><el-icon ><CaretTop /></el-icon></div>
                        <div :class="{'activeTriangle':activeTriangleUser==2?true:false}" @click="sortArr(2,'activityUserNum')" style='margin-top: -7px'><el-icon ><CaretBottom /></el-icon></div>

                    </div>
                </div>
                <div style='display: flex;align-items: center;justify-content: center'>
                    <div>用户活跃度</div>
                    <div style='padding-top: 5px;cursor: pointer' >
                        <div :class="{'activeTriangle':activeTriangleNum==1?true:false}" @click="sortArr(1,'userActivity')"><el-icon ><CaretTop /></el-icon></div>
                        <div :class="{'activeTriangle':activeTriangleNum==2?true:false}" @click="sortArr(2,'userActivity')" style='margin-top: -7px'><el-icon ><CaretBottom /></el-icon></div>

                    </div>
                </div>
<!--                <div v-if="listType!='area'">应用合计</div>-->
            </div>

            <div style='overflow: auto;height: 400px'>
                <!--            //区划列表-->
                <div v-for='(item,index) in tableData' :key="index+'area'"  v-if="listType=='area'">
                    <div class='tableItem' :style='{background:index>2?"rgba(153,153,153,0.05)":"rgba(255,154,59,0.05)" }'>
                        <div :style='{backgroundImage:index>2?"url("+img3+")":"url("+img4+")" }'>
                            {{index*1+1}}
                        </div>
                        <div>
                            <span class='name'>{{item.areaName}}</span>
                            <span class='progressOverall'>
                            <span
                                :style='{background:index>2?"#1492FF":"#FFA83B",width:item.userActivity+"%" }'></span>
                        </span>
                        </div>
                        <div :class='[{"active":index>2}]'>
                            {{item.activityUserNum}}
                        </div>
                        <div :class='[{"active":index>2}]'>
                            {{item.userActivity}}%
                        </div>
                    </div>
                </div>
                <!--            //应用列表-->
                <div v-for='(item,index) in tableData' :key="index+'application'" v-else>
                    <div class='tableItem' :style='{background:index>2?"rgba(153,153,153,0.05)":"rgba(255,154,59,0.05)" }'>
                        <div v-if='item.applicationName=="合计"'>

                        </div>
                        <div v-else :style='{backgroundImage:index>2?"url("+img3+")":"url("+img4+")" }'>
                            {{index*1+1}}
                        </div>
                        <div>
                            <span class='name'>{{item.applicationName}}</span>
                            <span class='progressOverall' v-if='item.applicationName!="合计"'>
                            <span
                                :style='{background:index>2?"#1492FF":"#FFA83B",width:item.userActivity+"%" }'></span>
                        </span>
                        </div>
                        <div :class='[{"active":index>2}]'>
                            {{item.activityUserNum}}
                        </div>
                        <div :class='[{"active":index>2}]'>
                            <span></span>
                            {{item.userActivity}}%
                        </div>
<!--                        <div :class='[{"active":index>2}]'>-->
<!--                            {{item.activityUserTotal}}-->
<!--                        </div>-->
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import http from '../../utils/request';
let img3=require('../../assets/img/activityStatistics/img3.png')
let img4=require('../../assets/img/activityStatistics/img4.png')
let query = ref({appUuidList:[]});
let activeTriangleNum=ref('')
let activeTriangleUser=ref('')
let time=formatDate(new Date().getTime()-60*1000*60*24)
console.log();
const explainList={
    area:[
        '取数时间：'+time,
        '1、日均活跃用户数：根据所选时间范围统计每日浙统云通讯录下各地区活跃用户数，求平均值得出日均活跃用户数（活跃用户数为0的日期不计算在内）；',
        '2、日均用户活跃度=日均用户活跃数/各地区浙统云通讯录下注册用户数*100%；',
        '3、累加用户活跃数：根据所选时间统计该时间范围内浙统云通讯录下累计活跃用户数量（同一用户选定时间范围内登录多次，只计入一次）；',
        '4、累加用户活跃度=累加用户活跃数/各地区浙统云通讯录下注册用户数*100%',
        '5、注册用户总数：浙统云通讯录下历史登录过的用户总数。'
    ],
    application:[
        '取数时间：'+time,
        '1、日均活跃用户数：根据所选时间范围统计每日浙统云通讯录下各应用活跃用户数，求平均值得出各应用日均活跃用户数（活跃用户数为0的日期不计算在内）；',
        '2、日均用户活跃度=日均用户活跃数/各地区浙统云通讯录下注册用户数*100%；',
        '3、累加用户活跃数：根据所选时间统计该时间范围内浙统云通讯录下累计活跃用户数量（同一用户选定时间范围内登录多次，只计入一次）；',
        '4、累加用户活跃度=累加用户活跃数/各地区浙统云通讯录下注册用户数*100%',
        '5、注册用户总数：浙统云通讯录下历史登录过的用户总数。'
    ],
}
const shortcuts = [
    {
        text: '今日',
        value: () => {
            const endTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
            const startTime = new Date(new Date(new Date().toLocaleDateString()).getTime())
            return [startTime, endTime]
        },
    },
    {
        text: '本月',
        value: () => {
            const date = new Date()
            const year = date.getFullYear()
            const month = date.getMonth()
            const thisMonthStartDate = formatDate(new Date(year,month,1))
            const thisMonthEndDate = formatDate(new Date(year,month+1,0))
            // 下个月第0天表示本月最后一天
            return [thisMonthStartDate+' 00:00:00', thisMonthEndDate+' 23:59:59']
        },
    },
    {
        text: '本年',
        value: () => {
            const date = new Date()
            const year = date.getFullYear()
            return [year+'01-01 00:00:00', year+'12-31 23:59:59']
        },
    },]
const props = defineProps({
    listType: String,
});
let propsRegion={label:"organizationName",value:"externalId",children:'children'}
let tableData = ref([]);
let option_region = ref([]);
let searchData = ref([])
let option_map=ref([])
let option_dimension=ref([{label:'日均活跃情况',value:'avg'},{label:'累加活跃情况',value:'sum'}])
// 获取数据list
function getList() {
    let arr=['/zty-api/system/activity/display/area','/zty-api/system/activity/display/application']
    let url=''
    if(props.listType=='area'){
        url=arr[0]
    }else{
        url=arr[1]
    }
    http.post(url, query.value).then((res) => {
            if(props.listType=='area'){
                tableData.value=res.data.areaUserActivityList
            }else{
                tableData.value=res.data.applicationUserActivityList

                let obj={
                    activityUserNum:res.data.activityUserTotal,userActivity:res.data.activityUserTotalActivity,applicationName:'合计'
                }
                tableData.value.push(obj)
            }

        })
        .catch((err) => {
            console.log(err);
        });
}
// 排序
function sortArr(type,key) {
    if(key!='userActivity'){
        activeTriangleNum.value=''
        activeTriangleUser.value=type
    }else{
        activeTriangleUser.value=''
        activeTriangleNum.value=type
    }
    let arr=tableData.value
    let newArr = arr.sort(function (a, b) {
        if(type==1){
            return b[key] - a[key]
        }else{
            return a[key] - b[key]
        }

    })
    tableData.value=newArr
}
// 搜索按钮
function search() {
    getList();
}
// 重置
function resize() {
    searchData.value = [];
    query.value = {};
    getList();
}
//时间change
function handleDate(val) {
    if(searchData.value){
        query.value.startTime = searchData.value[0]
        query.value.endTime  = searchData.value[1]

    }else{
        query.value.startTime = ''
        query.value.endTime  =''
    }
}
// 获取地区
function getSpecific() {
    http.get('/zty-api/system/activity/display/area/specific', {}).then((res) => {
            option_region.value=res.data
        })
        .catch((err) => {
            console.log(err);
        });
}
// 获取任务下拉
function getMpping() {

    http.get('/zty-api/system/activity/display/application/mapping', {}).then((res) => {
            option_map.value=res.data
        })
        .catch((err) => {
            console.log(err);
        });
}
function formatDate(val) {
    var date = new Date(Number(val));
    var Y = date.getFullYear() + "-";
    var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
    var D = date.getDate();
    D =D < 10 ? "0" + D: D;
    return Y + M + D
}
// 排序修改
function changeRank() {
    tableData.value = tableData.value.reverse();
}
onMounted(() => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const thisMonthStartDate = formatDate(new Date(year,month,1))
    const thisMonthEndDate = formatDate(new Date(year,month+1,0))
    searchData.value=[thisMonthStartDate,thisMonthEndDate]
    handleDate(searchData.value)
    query.value.type='avg'
    getList();
    getMpping();
    getSpecific();
});
</script>

<style scoped lang='less'>
.statisticsList{
    width: 49%;
    background: #FFFFFF;
    padding: 20px;
    box-sizing: border-box;
    .search{
        display: flex;
        justify-content: space-between
    }
    .title{
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div:nth-child(1) img{
            width: 18px;
            vertical-align: middle;
            .text{
                font-family: PingFangSC-Medium;
                font-weight: 600;
                font-size: 16px;
                color: #333333;
                margin-left: 8px;
                vertical-align: middle;
            }
        }
        &>div:nth-child(2){
            span{
                vertical-align: middle;
                margin-right: 5px;
                font-size: 12px;
            }
            img{
                width: 14px;
                vertical-align: middle;
            }
        }
    }
    .tableTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
        &>div:nth-child(1){
            width: 10%;
            text-align: center;
        }
        &>div:nth-child(2){
            width: 55%;
        }
        &>div:nth-child(3){
            width: 16%;
            text-align: center;
        }
        &>div:nth-child(4){
            width: 16%;
            text-align: center;
        }
        &>div:nth-child(5){
            width: 16%;
            text-align: center;
        }

    }
    .tableItem{
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        div:nth-child(1){
            height: 100%;
            line-height: 50px;
            background-repeat: no-repeat;
            background-position: bottom;

            //background-size: 100%;
        }
        div:nth-child(1){
            width: 10%;
            text-align: center;
            font-family: PingFangSC-Medium;
            font-weight: 600;
            font-size: 16px;
            color: #333333;

        }
        div:nth-child(2){
            width: 55%;
            display: flex;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;
            .name{
                width: 130px;
                display: inline-block;
            }
        }
        div:nth-child(3){
            width: 16%;
            text-align: center;
            font-weight: 700;
            font-size: 18px;
            color: #FFA83B;
            text-align: center;
        }
        div:nth-child(4){
            width: 16%;
            text-align: center;
            font-weight: 700;
            font-size: 18px;
            color: #FFA83B;
            text-align: center;
        }
        div:nth-child(5){
            width: 16%;
            text-align: center;
            font-weight: 700;
            font-size: 18px;
            color: #FFA83B;
            text-align: center;
        }
        .active{
            color:  #1492FF!important;
        }
    }
    .progressOverall{
        height: 20px;
        background: #EAEAEA;
        margin-left: 10px;
        border-radius: 12px;
        flex: 1;
        display: inline-block;
        overflow: hidden;
        span{
            height: 20px;
            display: inline-block;
            border-radius: 12px;
        }
    }
}
.el-form--inline .el-form-item{
    margin-right:15px;
}
.activeTriangle{
    color:#1492FF
}
</style>
