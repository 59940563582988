import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15ae9cf5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "activityStatistics" }
const _hoisted_2 = { class: "statisticsListBox" }

import axios from 'axios';
import statisticsTotal from './statisticsTotal';
import statisticsList from './statisticsList';
import store from '@/store';

export default {
  __name: 'activityStatistics',
  props: {},
  emits: ['close'],
  setup(__props, { emit }) {

const props = __props

const router = useRouter();
const route = useRoute();


const {} = props;
const test = computed(() => {
return null;
});
watch();
onMounted(() => {});
onUnmounted(() => {
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(statisticsTotal)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(statisticsList), { listType: 'area' }),
      _createVNode(_unref(statisticsList), { listType: 'application' })
    ])
  ]))
}
}

}